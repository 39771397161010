import axios from '@/plugins/axios';

class ProviderService {
  getProviders(data) {
    this.data = data;
    return axios().get('admin/providers', { params: this.data }).then((response) => response.data);
  }

  getProvider(id) {
    this.id = id;
    return axios().get(`admin/providers/${id}`).then((response) => response.data);
  }

  createProvider(data) {
    this.data = data;
    return axios().post('admin/providers', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  updateProvider(id, data) {
    this.id = id;
    return axios().put(`admin/providers/${id}`, data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteProvider(id) {
    this.id = id;
    return axios().delete(`admin/providers/${id}`).then((response) => response.data);
  }
}

export default new ProviderService();
